/* PrivacyPolicy.css */
.privacy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.privacy-content {
  max-width: 800px;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacy-container h1, .privacy-container h2 {
  color: #333;
}

.privacy-container h1 {
  text-align: center;
  font-size: 24px;
}

.privacy-container h2 {
  margin-top: 20px;
  font-size: 20px;
}

.privacy-container h3 {
  font-size: 18px;
  margin-top: 15px;
}

.privacy-container p, .privacy-container ul {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

.privacy-container .last-updated {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.privacy-container ul {
  /* comment */
  padding-left: 20px;
}

.privacy-container a {
  color: #007bff;
  text-decoration: none;
}

.privacy-container a:hover {
  text-decoration: underline;
}
